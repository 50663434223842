import React, { useState } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Logo from "../images/Logox150.png"

const StyledNavContainer = styled.div`
  position: fixed;
  top: 0;
  display: flex;
  justify-content: space-around;
  width: 100%;
  height: 60px;
  background-color: whitesmoke;
  z-index: 10;
  box-shadow: 5px 10px 10px;
`
const StyledLogoContainer = styled.div`
  display: flex;
  font-family: "Orbitron", sans-serif;
  align-items: center;

  i {
    width: 20px;
    font-size: 1.5em;
    display: none;
    transition-duration: 0.4s;
    cursor: pointer;

    @media (max-width: 1000px) {
      display: block;
    }
    @media (max-width: 550px) {
      display: block;
      padding: 0 1rem;
    }
  }

  img {
    width: 60px;
    height: 60px;
    @media (max-width: 1000px) {
      display: none;
    }
  }
  p {
    font-size: 2em;
    margin: 0 0.5rem;

    @media (max-width: 1000px) {
      display: none;
    }
  }
`
const StyledNavLinks = styled.div`
  width: 550px;
  align-self: center;
  font-family: "Roboto", sans-serif;

  .dropdown {
    position: relative;
  }
  .dropdown-content {
    width: 150px;
    padding: 1rem;
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    box-shadow: 10px 8px 16px 10px;
    text-align: center;
    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
      flex-direction: column;
    }
    li {
      margin: 0.6rem 0;
      &:hover {
        color: blue;
      }
    }
  }

  .dropdown-content-index {
    width: 200px;
    padding: 1rem;
    display: none;
    position: absolute;
    background-color: transparent;
    box-shadow: 10px 8px 16px 10px;
    text-align: center;
    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
      flex-direction: column;
    }
    li {
      margin: 0.6rem 0;
      &:hover {
        color: blue;
      }
    }
  }
  .dropdown:hover .dropdown-content {
    display: block;
  }

  ul {
    list-style-type: none;
    display: flex;
    padding: 0;
    align-items: center;
    justify-content: space-around;
    margin: 0;
    @media (max-width: 1000px) {
      display: none;
    }
  }

  li {
    cursor: pointer;
  }

  .mobile-logo {
    display: none;

    font-family: "Orbitron", sans-serif;
    align-items: center;

    img {
      width: 60px;
      height: 60px;
    }
    p {
      font-size: 1.5em;
      margin: 0 0.5rem;
    }
    @media (max-width: 1000px) {
      display: flex;
    }
  }
`

const StyledSocialMediaContainer = styled.div`
  display: flex;
  width: 80px;
  justify-content: space-between;
  align-self: center;
  @media (max-width: 1000px) {
    display: none;
  }
`

const StyledMobileNav = styled.div`
  position: fixed;
  width: 300px;
  height: 450px;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 3;
  margin-top: 60px;
  border-right: 1px solid white;
  border-bottom-right-radius: 50%;
  transition-duration: 0.5s;

  ul {
    list-style-type: none;
    color: white;
    font-family: "Roboto", sans-serif;
    /* text-align: center; */
    padding: 0;
    font-size: 1.5em;

    li {
      padding: 1rem;
      margin-left: 3.5rem;
    }
  }
  .mobile-social-container {
    display: flex;
    color: white;
    justify-content: space-between;
    font-size: 1.5em;
    width: 150px;
    margin: 0 auto;
  }

  select {
    padding: 0;
    background: none;
    color: white;
    border: none;
    font-size: 1em;
    margin: 0;
    /* text-align: center; */
    appearance: none;
    cursor: pointer;
  }
`

const MainNav = () => {
  const HandleSelect = e => {
    window.location = e.target.value
  }

  const [mobileNav, setMobileNav] = useState({
    bars: "fas fa-bars",
    marginLeft: "-320px",
  })

  function toggleNav() {
    if (mobileNav.marginLeft < "0") {
      setMobileNav({
        marginLeft: "0",
        bars: "fas fa-times",
      })
    } else setMobileNav({ marginLeft: "-320px", bars: "fas fa-bars" })
  }
  return (
    <>
      <StyledNavContainer>
        <StyledLogoContainer>
          <img src={Logo} alt="" />
          <p>Geek Life Radio</p>
          <i onClick={toggleNav} className={mobileNav.bars} />
        </StyledLogoContainer>

        <StyledNavLinks>
          <div className="mobile-logo">
            <img src={Logo} alt="" />
            <p>Geek Life Radio</p>
          </div>
          <ul>
            <Link style={{ textDecoration: "none", color: "black" }} to="/">
              <li>Home</li>
            </Link>
            <Link
              style={{ color: "black", textDecoration: "none" }}
              to="/schedule"
            >
              <li>Schedule</li>
            </Link>
            <li className="dropdown">
              Audio
              <span style={{ marginLeft: "5px" }}>
                <i className=" fas fa-sort-down" />
              </span>
              <div className="dropdown-content">
                <ul>
                  <Link
                    to="/podcasts"
                    style={{ color: "black", textDecoration: "none" }}
                  >
                    <li>Podcasts</li>
                  </Link>
                  <Link
                    to="/artists"
                    style={{ color: "black", textDecoration: "none" }}
                  >
                    <li>Artists</li>
                  </Link>
                </ul>
              </div>
            </li>

            <li className="dropdown">
              Submissions
              <span style={{ marginLeft: "5px" }}>
                <i className=" fas fa-sort-down" />
              </span>
              <div className="dropdown-content">
                <ul>
                  <Link
                    to="/music-submission"
                    style={{ color: "black", textDecoration: "none" }}
                  >
                    <li>Music Submission</li>
                  </Link>
                  <Link
                    to="/show-submission"
                    style={{ color: "black", textDecoration: "none" }}
                  >
                    <li>Show Submission</li>
                  </Link>
                </ul>
              </div>
            </li>
            <a
              style={{ color: "black", textDecoration: "none" }}
              href="https://stream.radiojar.com/4qe8m62pa8zuv.m3u"
              target="_blank"
            >
              <li>
                Listen Live{" "}
                <i
                  className="fas fa-play-circle"
                  style={{ color: "#164C9A" }}
                ></i>
              </li>
            </a>
          </ul>
        </StyledNavLinks>

        <StyledSocialMediaContainer>
          <a
            style={{ color: "black", textDecoration: "none" }}
            href="https://twitter.com/GeekLifeRadio"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fab fa-twitter" />
          </a>
          <a
            style={{ color: "black", textDecoration: "none" }}
            href="https://www.facebook.com/GeekLifeRadio/"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fab fa-facebook-f" />
          </a>
          <a
            style={{ color: "black", textDecoration: "none" }}
            href="https://www.instagram.com/geekliferadio/"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fab fa-instagram" />
          </a>
        </StyledSocialMediaContainer>
      </StyledNavContainer>
      <StyledMobileNav style={{ marginLeft: mobileNav.marginLeft }}>
        <ul>
          {" "}
          <a
            style={{ color: "yellow", textDecoration: "none" }}
            href="http://stream.radiojar.com/4qe8m62pa8zuv.m3u"
            target="_blank"
          >
            <li>
              Listen Live <i className="fas fa-play-circle"></i>
            </li>
          </a>
          <Link style={{ color: "white", textDecoration: "none" }} to="/">
            <li>Home</li>
          </Link>
          <Link
            style={{ color: "white", textDecoration: "none" }}
            to="/schedule"
          >
            <li>Schedule</li>
          </Link>
          <li>
            <select onChange={HandleSelect}>
              <option selected disabled>
                Audio
              </option>

              <option value="/podcasts">Podcasts</option>

              <option value="/artists">Artists</option>
            </select>
          </li>
          <li>
            <select onChange={HandleSelect}>
              <option selected disabled>
                Submission
              </option>

              <option value="/music-submission">Music Submission</option>

              <option value="/show-submission">Show Submission</option>
            </select>
          </li>
        </ul>
        <div className="mobile-social-container">
          <a
            style={{ color: "white", textDecoration: "none" }}
            href="https://www.twitter.com/GeekLifeRadio/"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fab fa-twitter" />
          </a>
          <a
            style={{ color: "white", textDecoration: "none" }}
            href="https://www.facebook.com/GeekLifeRadio/"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fab fa-facebook-f" target="_blank" rel="noreferrer" />
          </a>
          <a
            style={{ color: "white", textDecoration: "none" }}
            href="https://www.instagram.com/GeekLifeRadio/"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fab fa-instagram" />
          </a>
        </div>
      </StyledMobileNav>
    </>
  )
}

export default MainNav
