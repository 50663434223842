import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { StyledRadioPlayer } from "../components/styles/AudioPlayerv2Styles"
import { Link } from "gatsby"

const AudioPlayerv2 = () => {
  const [playButton, setPlayButton] = useState("fas fa-play-circle")
  const [radioData, setRadioData] = useState({})

  const CORS_PROXY = "https://geek-life-radio-proxy.herokuapp.com/?q="
  const url =
    "https://now-dot-playing-dot-radiojarcom.appspot.com/api/stations/4qe8m62pa8zuv/now_playing/"

  async function handleData() {
    let response = await fetch(`${CORS_PROXY}${url}`)
    response.json().then(response => setRadioData(response))
  }

  useEffect(() => {
    handleData()
    const interval = setInterval(() => {
      handleData()
    }, 35000)
  }, [])

  function ToggleAudio() {
    document.getElementById("player").play()
    setPlayButton("fas fa-pause-circle")
    if (playButton === "fas fa-pause-circle") {
      document.getElementById("player").pause()
      setPlayButton("fas fa-play-circle")
    }
  }
  return (
    <StyledRadioPlayer>
      <div className="container">
        <div id="album-container">
          <img src={radioData.thumb} alt="radioData.title" />
        </div>
        <div id="audio-content">
          <div id="player-row1">
            <div id="play-btn" onClick={ToggleAudio}>
              <i className={playButton} />
            </div>
            <div className="text-info">
              <h3>{radioData.artist}</h3>
              <marquee style={{ width: "250px" }}>
                <p>{radioData.title}</p>
              </marquee>
            </div>
            <audio style={{ display: "none" }} id="player">
              <source src="https://stream.radiojar.com/4qe8m62pa8zuv" />
            </audio>
          </div>
          <div id="player-row2">
            <a href={radioData.buy_urls}>
              <button className="more-btns">
                Buy Track <i className="fas fa-shopping-cart" />
              </button>
            </a>
            <Link to={radioData.info_urls}>
              <button className="more-btns">
                Artist Info <i className="fas fa-info-circle" />
              </button>
            </Link>
          </div>
        </div>
      </div>
    </StyledRadioPlayer>
  )
}

export default AudioPlayerv2
