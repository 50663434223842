import styled from "styled-components"

export const StyledRadioPlayer = styled.div`
  .container {
    position: fixed;
    bottom: 0;
    display: flex;
    width: 100%;
    height: 130px;
    background-color: rgba(0, 0, 0, 0.8);

    #album-container {
      position: fixed;
      width: 150px;
      height: 150px;
      margin-top: -20px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    #audio-content {
      position: fixed;
      margin-left: 20px;
      left: 140px;

      #player-row1 {
        display: flex;
        margin-top: 10px;

        h3 {
          color: whitesmoke;
          font-family: "Orbitron", sans-serif;
          font-size: 1.3em;
          margin: 5px 0 0 15px;
          @media (max-width: 500px) {
            width: 100px;
            font-size: 1em;
          }
        }
        p {
          color: whitesmoke;
          font-family: "Roboto", sans-serif;
          margin: 0 0 0 15px;
          font-size: 1.1em;
          @media (max-width: 500px) {
            font-size: 0.9em;
          }
        }

        #play-btn {
          background: transparent;
          border: none;
          font-size: 4em;
          color: yellow;
          cursor: pointer;
          align-self: center;
        }
      }
    }

    .more-btns {
      padding: 0.3rem;
      margin: 0.5rem;
      background-color: #465e7d;
      border: none;
      color: white;
      padding: 0.5rem;
      border-radius: 10px;
      cursor: pointer;
    }
  }
`
