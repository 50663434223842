import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Logo from "../images/Logox150.png"

const StyledFooter = styled.div`
  bottom: 0;
  width: 100%;
  min-height: 100px;
  background-color: #132039;
  color: white;
  font-family: "Roboto", sans-serif;
  margin-bottom: 130px;

  .footer-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    @media (max-width: 800px) {
      text-align: center;
    }
  }

  ul {
    list-style-type: none;
    /* font-family: "Roboto", sans-serif; */
    @media (max-width: 800px) {
      text-align: center;
    }

    li {
      padding: 0.3rem;
      color: whitesmoke;
      text-decoration: none;
      @media (max-width: 800px) {
        text-align: center;
      }
    }
  }

  .footer-logo {
    padding: 0.5rem;
    display: flex;
    font-family: Orbitron;
    color: yellow;

    .logo-container {
      width: 60px;
      height: 60px;
      background-color: whitesmoke;
      border-radius: 50%;
      border: 2px solid #be1e2d;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
`

const Footer = () => {
  return (
    <>
      <StyledFooter>
        <div className="footer-container">
          <div className="footer-elm">
            <h3>Support</h3>
            <ul>
              <li>
                <Link
                  style={{ color: "whiteSmoke", textDecoration: "none" }}
                  to="/contact"
                >
                  Contact Us
                </Link>
              </li>
              {/* <li>
                <Link
                  style={{ color: "whiteSmoke", textDecoration: "none" }}
                  to="/advertise"
                >
                  Advertise
                </Link>
              </li> */}
            </ul>
          </div>
          <div className="footer-elm">
            <div className="footer-elm">
              <h3>About Geek Life Radio</h3>
              <ul>
                <li>
                  <Link
                    style={{ color: "whiteSmoke", textDecoration: "none" }}
                    to="/about"
                  >
                    About Us
                  </Link>
                </li>
                <li>
                  <Link
                    style={{ color: "whiteSmoke", textDecoration: "none" }}
                    to="/articles"
                  >
                    Articles
                  </Link>
                </li>
                {/* <li>
                  <Link
                    style={{ color: "whiteSmoke", textDecoration: "none" }}
                    to="/faq"
                  >
                    FAQ
                  </Link>
                </li> */}
              </ul>
            </div>
          </div>
          <div className="footer-elm">
            <h3>Join our Network</h3>
            <ul>
              <li>
                <Link
                  style={{ color: "whiteSmoke", textDecoration: "none" }}
                  to="/music-submission"
                >
                  Music Submissions
                </Link>
              </li>
              <li>
                <Link
                  style={{ color: "whiteSmoke", textDecoration: "none" }}
                  to="/show-submission"
                >
                  Show Submissions
                </Link>
              </li>
              {/* <li>
                <Link
                  style={{ color: "whiteSmoke", textDecoration: "none" }}
                  to="/dj-submission"
                >
                  Become a DJ
                </Link>
              </li> */}
            </ul>
          </div>
          <div className="footer-elm">
            <h3>Connect with us</h3>
            <ul>
              <li>
                <a
                  style={{ color: "whiteSmoke", textDecoration: "none" }}
                  href="https://twitter.com/GeekLifeRadio"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-twitter" />
                </a>
              </li>
              <li>
                <a
                  style={{ color: "whiteSmoke", textDecoration: "none" }}
                  href="https://www.facebook.com/GeekLifeRadio/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-facebook-f" />
                </a>
              </li>
              <li>
                <a
                  style={{ color: "whiteSmoke", textDecoration: "none" }}
                  href="https://www.instagram.com/geekliferadio/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-instagram" />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer-logo">
          <div className="logo-container">
            <img src={Logo} alt="Geek Life Radio Art" />
          </div>
          <h2>Geek Life Radio</h2>
        </div>
      </StyledFooter>
    </>
  )
}

export default Footer
